'use client'

import Image, { ImageProps } from 'next/image'

import { FC, useState } from 'react'

import { LoadingSpinner } from './LoadingSpinner'

export interface AvatarProps {
    imageUrl: string | undefined
    name: string
    isLoading?: boolean
    largeMode?: boolean
}

export const Avatar: FC<AvatarProps> = ({ imageUrl, name, isLoading, largeMode }) => {
    const [isValidSrc, setIsValidSrc] = useState(true)
    const imageProps: ImageProps = {
        src: imageUrl || '',
        alt: name,
        width: largeMode ? 96 : 48,
        height: largeMode ? 96 : 48,
        onErrorCapture: error => {
            console.warn('Avatar', error)
            setIsValidSrc(false)
        },
        onLoadingComplete: result => {
            if (result.naturalWidth === 0) {
                // Broken image
                setIsValidSrc(false)
            }
        }
    }
    let avatar

    if (imageUrl && isValidSrc) {
        avatar = <Image {...imageProps} alt={name} />
    }
    // use first letter in name
    else {
        avatar = <div className='text-center text-lg text-white cursor-default select-none'>{name.charAt(0)}</div>
    }

    return <div className={`grid items-center rounded-full ${largeMode ? 'w-24 h-24' : 'w-12 h-12'} overflow-hidden bg-neutral-400`}>{isLoading ? <LoadingSpinner size={4} /> : avatar}</div>
}
