import { Component, FC } from 'react'

import { sideMenuItem } from '@closer/headless-components/components'

import { useValidRoute } from '../../hooks/router/useValidRoute'

import { RemindersViewToggle } from '../Reminders/RemindersViewToggle'
import { StartNewChatButton } from '../RoomList/StartNewChatButton'
import { SyncContact } from '../ContactSync/SyncContact'

const ReminderOptions: FC = ({}) => {
    return (
        <div>
            <RemindersViewToggle />
        </div>
    )
}

const RoomListOptions: FC = ({}) => {
    return (
        <div>
            <StartNewChatButton />
        </div>
    )
}

const SyncedContactOptions: FC = () => {
    return (
        <div>
            <SyncContact />
        </div>
    )
}

class FeatureOption extends Component {
    static [sideMenuItem.RoomList] = RoomListOptions
    static [sideMenuItem.Reminders] = ReminderOptions
    static [sideMenuItem.ScheduledMessages] = () => <></>
    static [sideMenuItem.Settings] = () => <></>
    static 'Archive' = () => <></>
    static 'Contact' = SyncedContactOptions
}

export const FeatureOptions: FC = () => {
    const { f, isValid } = useValidRoute({ f: _f => _f in FeatureOption })

    if (isValid) {
        const ActiveFeatureOption = FeatureOption[f]
        return <ActiveFeatureOption />
    }

    // FIXME: base on url state
    return <RoomListOptions />
}
