import { useRouter } from 'next/router'
import { ComponentProps, FC } from 'react'

import { authService } from '@closer/matrix'

export const LogoutButton: FC = () => {
    const router = useRouter()
    const logoutProps: ComponentProps<'button'> = {
        className: 'py-1 rounded font-bold text-red-500 bg-neutral-100',
        onClick: () => authService.logout().then(() => router.push('/'))
    }

    return <button {...logoutProps}>Logout</button>
}
