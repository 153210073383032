import { forwardRef, ReactElement } from 'react'

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

export interface SearchBarProps {
    scope: string
    onChangeText: (input: string) => void
    options?: Array<ReactElement>
    showReset?: boolean
    label?: string
    onFocus?: () => void
    onBlur?: () => void
    defaultValue?: string
}

export const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>(
    ({ scope, options, defaultValue, label, showReset, onChangeText, onFocus, onBlur }, ref) => {
        return (
            <div className='flex flex-row relative bg-gray-200'>
                <MagnifyingGlassIcon className='w-6 h-6 absolute top-4 left-5 text-gray-500' />
                <input
                    ref={ref}
                    className='flex grow rounded-full mx-2 my-2 h-10 pl-10 border-none'
                    aria-label={label}
                    type='text'
                    onChange={event => {
                        onChangeText(event.target.value)
                    }}
                    onFocus={() => {
                        onFocus && onFocus()
                    }}
                    onBlur={() => {
                        onBlur && onBlur()
                    }}
                    placeholder={`Search ${scope}`}
                    defaultValue={defaultValue}
                />
                {showReset && <div>reset</div>}
                {options && <div>options</div>}
            </div>
        )
    }
)
