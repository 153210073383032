import { FC } from 'react'

import { RemindersViewToggleProps as HeadlessProps, RemindersViewToggle as HeadlessToggle } from '@closer/headless-components/components'

import { mdAccountClock, mdClipboardTextClock, Svg, SvgProps } from '../Common'

export const RemindersViewToggle: FC = () => {
    const headlessProps: HeadlessProps = {
        render: ({ viewMode, toggleViewMode }) => {
            const iconProps: SvgProps = {
                stroke: 'none',
                fill: '#56BA8E',
                className: 'w-8 h-8 p-1 rounded-md hover:bg-[#00000010] cursor-pointer',
                onClick: toggleViewMode
            }

            if (viewMode === 'Group') {
                return <Svg {...iconProps} d={mdAccountClock} />
            }

            return <Svg {...iconProps} d={mdClipboardTextClock} />
        }
    }

    return <HeadlessToggle {...headlessProps} />
}
