import * as Sentry from '@sentry/nextjs'

import { useDatabase } from '@nozbe/watermelondb/hooks'
import { useCallback, useEffect } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { matrixService } from '@closer/matrix'
import { reminderApi } from '@closer/headless-components/components/Reminders'
import { scheduleApi } from '@closer/headless-components/components/ScheduleSend'
import { TableName } from '@closer/watermelondb'
import { useMatrix } from '@closer/headless-components/hooks'
import { api, mapRemindersTime, mapScheduleSendsTime } from '@closer/api'
import { Q, ReminderSubCategory, RemindItem, TenantUserAuth } from '@closer/types'
import { setMatrixStartRefreshAt, setMatrixStateIsCorrupted, useAppDispatch, useAppSelector } from '@closer/redux-storage'

import { labelService, localStorage, syncBackendDataService } from 'apps/web/pages/_app'

export const useBackendSync = () => {
    useSyncLabel()
    useSyncBackendData()
    useRoomStateChecking()
}

const refetchInterval = 5 * 60 * 1000
const staleTime = 60 * 1000
const useSyncLabel = () => {
    const matrixIsSynced = useAppSelector(state => state.matrix.matrixIsSynced)
    const { data: labels } = useQuery([Q.ACCOUNT_ROOM_TAGS], api.accountRoomTag.readV2, { staleTime, refetchInterval, enabled: !!matrixIsSynced })
    const { data: labelRecords } = useQuery([Q.ROOM_LABEL_RECORDS], api.labelRecords.read, { staleTime, refetchInterval, enabled: !!matrixIsSynced })
    const syncLabels = useCallback(async () => {
        if (matrixIsSynced && labels && labelRecords) {
            try {
                await labelService.updateAllLabels(labels)
                await labelService.updateAllLabelRecords(labelRecords)
            } catch (error) {
                console.error(error)
                Sentry.captureException(error)
            }
        }
    }, [matrixIsSynced, labels, labelRecords])

    useEffect(() => {
        syncLabels()
    }, [syncLabels])
}

const useSyncBackendData = () => {
    const queryClient = useQueryClient()
    const matrixIsSynced = useAppSelector(state => state.matrix.matrixIsSynced)
    // FIXME: was using the same query key with the reminders, writing to watermelondb at the same time will cause crash, have to decide how to improve
    const { data: reminders } = useQuery([Q.NO_CACHE, Q.REMIND_ITEMS], () => reminderApi.read({ status: 'active' }), {
        staleTime,
        refetchInterval,
        cacheTime: 0,
        enabled: !!matrixIsSynced,
        onSuccess: response => {
            if (!response?.length) {
                return
            }

            const { Pending, Completed } = response.reduce(
                (acc, item) => {
                    if (item.isDone) {
                        acc.Completed.push(item)
                    } else {
                        acc.Pending.push(item)
                    }
                    return acc
                },
                { Pending: [], Completed: [] } as Record<ReminderSubCategory, Array<RemindItem>>
            )

            queryClient.setQueryData([Q.REMIND_ITEMS, 'Pending'], Pending)
            queryClient.setQueryData([Q.REMIND_ITEMS, 'Completed'], Completed)
        }
    })
    const { data: scheduleSends } = useQuery([Q.NO_CACHE, Q.SCHEDULE_MESSAGES], () => scheduleApi.read(), {
        staleTime,
        refetchInterval,
        cacheTime: 0,
        enabled: !!matrixIsSynced,
        onSuccess: async response => {
            if (!response?.length) {
                return
            }

            localStorage.getItem('CLOSER_AUTH').then((auth: TenantUserAuth | null) => {
                if (!auth) {
                    return
                }

                queryClient.setQueryData([Q.SCHEDULE_MESSAGES, auth.id], response)
            })
        }
    })
    const { data: roomAccountData } = useQuery([Q.NO_CACHE, Q.ROOM_ACCOUNT_DATA], () => api.roomAccountData.read(undefined), {
        staleTime,
        refetchInterval,
        cacheTime: 0,
        enabled: !!matrixIsSynced,
        onSuccess: response => {
            if (!response?.length) {
                return
            }

            for (const data of response) {
                queryClient.setQueryData([Q.ROOM_ACCOUNT_DATA, data.roomId], data)
            }
        }
    })
    const syncBackendData = useCallback(async () => {
        if (matrixIsSynced && reminders && scheduleSends && roomAccountData) {
            try {
                const mappedReminders = mapRemindersTime(reminders.filter(({ isDone }) => !isDone))
                const mappedSchedules = mapScheduleSendsTime(scheduleSends)
                await syncBackendDataService.syncBackendData(roomAccountData, mappedReminders, mappedSchedules)
            } catch (error) {
                console.error(error)
                Sentry.captureException(error)
            }
        }
    }, [matrixIsSynced, reminders, scheduleSends, roomAccountData])

    useEffect(() => {
        syncBackendData()
    }, [syncBackendData])
}

const useRoomStateChecking = () => {
    const { client } = useMatrix()
    const database = useDatabase()
    const queryClient = useQueryClient()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!client) {
            return
        }
        const checkRoomState = async () => {
            try {
                const visibleRooms = client.getVisibleRooms()
                const joinedRooms = (await client.getJoinedRooms()).joined_rooms
                // log.info(`Matrix Rooms:${visibleRooms.length}`)
                // log.info(`Matrix Joined Rooms: ${joinedRooms.length}`)
                if (visibleRooms.length === 0 && joinedRooms.length !== 0) {
                    // FIXME: capture error to sentry
                    // log.error('Matrix memoryStore corrupted')
                    dispatch(setMatrixStartRefreshAt(Date.now()))
                    dispatch(setMatrixStateIsCorrupted(true))
                    await database.write(async () => {
                        if (!database) {
                            return
                        }
                        // log.info('Start reset sync Data in db')
                        const joined = await database.get(TableName.JOIN_ROOMS).query().fetch()
                        const invited = await database.get(TableName.INVITE_ROOMS).query().fetch()
                        const leaved = await database.get(TableName.LEAVE_ROOMS).query().fetch()
                        const toDelete = [...joined, ...invited, ...leaved].map(roomInfo => roomInfo.prepareDestroyPermanently())
                        await database.batch([...toDelete])
                        const syncData = await database.get(TableName.SYNCS).query().fetch()
                        await database.batch([...syncData.map(data => data.prepareDestroyPermanently())])
                        // log.info('End reset sync Data in db')
                    })
                    return matrixService.restartClient(queryClient, () => null)
                }
            } catch (error) {
                console.error(error)
                Sentry.captureException(error)
            }
        }
        const interval = setInterval(checkRoomState, 5 * 60 * 1000)

        return () => clearInterval(interval)
    }, [client, database, queryClient, dispatch])
}
