import { FC } from 'react'

import { Svg, SvgProps } from './Svg'

interface StubProps {
    label?: string
}

export const Stub: FC<StubProps> = ({ label }) => {
    const svgProps: SvgProps = {
        stroke: '#00000005',
        fill: 'none',
        d: 'M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9',
        className: 'w-24 h-24'
    }

    return (
        <div className='w-full h-full grid place-items-center'>
            <Svg {...svgProps} />
            {label && <p className='text-[#00000025]'>{label}</p>}
        </div>
    )
}
