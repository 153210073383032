import { ComponentProps, FC } from 'react'

export interface SvgProps extends Omit<ComponentProps<'svg'>, 'd' | 'strokeWidth' | 'viewBox'> {
    box?: { x: number; y: number; w: number; h: number }
    d?: string
    strokeWidth?: number
    paths?: Array<ComponentProps<'path'>>
}

export const Svg: FC<SvgProps> = ({ box, strokeWidth, d, paths, ...rest }) => {
    const props: ComponentProps<'svg'> = {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: box ? `${box.x} ${box.y} ${box.w} ${box.h}` : '0 0 24 24',
        strokeWidth: strokeWidth ? `${strokeWidth}` : '2',
        ...rest
    }

    if (paths) {
        return (
            <svg {...props}>
                {paths.map((pathProps, idx) => (
                    <path strokeLinecap='round' strokeLinejoin='round' {...pathProps} key={idx} />
                ))}
            </svg>
        )
    }

    return (
        <svg {...props}>
            <path strokeLinecap='round' strokeLinejoin='round' d={d} />
        </svg>
    )
}
