import { ComponentProps, FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'

type PartialDivProps = PropsWithChildren & Omit<ComponentProps<'div'>, 'onDrop' | 'onDragOver'>

export type DropBoxProps = PartialDivProps & {
    isEnabled?: boolean
    onFilesDropped: (data: FormData) => void
}

export const DropBox: FC<DropBoxProps> = ({ isEnabled, onFilesDropped, children, className, ...props }) => {
    const [isDragOver, setIsDragOver] = useState(false)
    const formRef = useRef<HTMLFormElement>(null)
    const containerProps: ComponentProps<'div'> = {
        ...props,
        className: className && !className.includes('relative') ? `relative ${className}` : className,
        onDragOver: evt => evt.preventDefault(),
        onDragEnter: evt => {
            evt.preventDefault()
            setIsDragOver(true)
        }
    }
    const formProps: ComponentProps<'form'> = {
        className: `absolute top-0 left-0 w-full h-full ${isDragOver ? 'bg-red-200 opacity-50' : 'pointer-events-none'}`
    }
    const handleDrop = useCallback(
        (evt: DragEvent) => {
            evt.preventDefault()

            if (formRef.current && evt.dataTransfer?.files.length) {
                const formData = new FormData(formRef.current)
                Array.from(evt.dataTransfer.files).forEach(file => formData.append(file.name, file))
                onFilesDropped(formData)
                setIsDragOver(false)
            }
        },
        [onFilesDropped]
    )
    const handleDragLeave = (evt: DragEvent) => {
        evt.preventDefault()
        setIsDragOver(false)
    }

    useEffect(() => {
        const form = formRef.current

        form?.addEventListener('drop', handleDrop)
        form?.addEventListener('dragleave', handleDragLeave)

        return () => {
            form?.removeEventListener('drop', handleDrop)
            form?.removeEventListener('dragleave', handleDragLeave)
        }
    }, [handleDrop])

    return (
        <div {...containerProps}>
            {children}
            <form {...formProps} ref={formRef} />
        </div>
    )
}

export * from './FilePreview'
