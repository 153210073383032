import { ComponentProps, forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

import styles from './FlexInput.module.css'

export interface FlexInputProps extends ComponentProps<'textarea'> {
    containerStyle?: string
}

export const FlexInput = forwardRef<HTMLTextAreaElement | void, FlexInputProps>(({ containerStyle, ...rest }, ref) => {
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const inputProps: ComponentProps<'textarea'> = { ...rest, rows: 1 }

    useEffect(() => {
        if (!inputRef.current || !inputRef.current.parentElement) {
            return
        }

        const value = !rest.value ? '' : Array.isArray(rest.value) ? rest.value.join('\n') : `${rest.value}`

        inputRef.current.parentElement.setAttribute('data-replicated-value', value)
    }, [rest.value])

    useImperativeHandle(ref, () => {
        if (!inputRef.current) {
            return
        }

        return inputRef.current
    })

    return (
        <div className={`${styles['flex-input-container']} ${containerStyle}`}>
            <textarea {...inputProps} ref={inputRef} />
        </div>
    )
})
