import { createPortal } from 'react-dom'
import { FC, PropsWithChildren, useMemo } from 'react'

interface ModalProps extends PropsWithChildren {
    selector: '#modal'
}

export const ModalPortal: FC<ModalProps> = ({ children, selector }) => {
    const domNode = useMemo(() => document.querySelector(selector), [selector])
    return domNode ? createPortal(children, domNode) : null
}
