import Image, { ImageProps } from 'next/image'

import { ISendEventResponse } from 'matrix-js-sdk'
import { ComponentProps, FC, forwardRef, useMemo, useRef, useState } from 'react'

import { useMatrix } from '@closer/headless-components/hooks'
import { IMessageContent, Rect } from '@closer/types'
import { makeFileContent, makeImageContent, makeSendableContent, makeVideoContent } from '@closer/utils'

import { Svg } from '../Svg'
import { biFilePdf, hiCancel, hiMusicalNote, hiNoSymbol, hiPaperPlane, miAttachment } from '../svgPaths'

import { LoadingSpinner } from '../LoadingSpinner'
import { Stub } from '../Stub'

type FilesPreviewItemProps = {
    type: string
    url?: string
    file?: File
    onToggleDiscard: (isDiscarded: boolean) => void
}

export type FilesPreviewProps = {
    roomId: string
    formData?: FormData
    onSend?: () => void
    onSendResponse?: (response: ISendEventResponse) => void
    onDismiss: (data: undefined) => void
}

const previewContainerStyle = 'absolute grid grid-rows-[1fr,min-content] grid-cols-[1fr,min-content,1fr] gap-4 items-center w-full h-full p-10 pb-4 pr-0 bg-white overflow-x-auto'
const itemContainerStyle = 'col-span-3 grid grid-cols-[repeat(3,minmax(0,1fr))] gap-4 place-items-center w-full h-full pr-10 pb-4 overflow-y-auto'

export const FilesPreview: FC<FilesPreviewProps> = ({ roomId, formData, onSend, onSendResponse, onDismiss }) => {
    const { client } = useMatrix()
    const [isSending, setIsSending] = useState(false)
    const dimensionsRef = useRef<Record<string, Rect>>({})
    const durationsRef = useRef<Record<string, number>>({})
    const sendLabelRef = useRef<HTMLParagraphElement>(null)
    const confirmedFiles = useRef<Set<File>>(new Set())
    const dismissProps: ComponentProps<'button'> = {
        className: `justify-self-end w-10 h-10 p-2 rounded-full bg-neutral-200 ${isSending ? 'opacity-80' : ''}`,
        onClick: () => onDismiss(undefined)
    }
    const sendProps: ComponentProps<'button'> = {
        className: `w-10 h-10 p-2 rounded-full bg-[#56BA8E] ${isSending ? 'opacity-80' : ''}`,
        onClick: async () => {
            if (client) {
                setIsSending(true)
                onSend && onSend()

                if (sendLabelRef.current) {
                    sendLabelRef.current.innerText = `${getSendLabel().replace(/^Send/, 'Sending')}...`
                }

                for (const file of confirmedFiles.current) {
                    const { content_uri } = await client.uploadContent(file, { onlyContentUri: false })
                    const [type, subType] = file.type.split('/')
                    let content: IMessageContent

                    if (type === 'image') {
                        content = makeImageContent(content_uri, subType, file.name, file.size, dimensionsRef.current[file.name])
                    }
                    //
                    else if (type === 'video') {
                        console.log('FilesPreview', durationsRef.current[file.name])
                        content = makeVideoContent(content_uri, subType, file.name, file.size, dimensionsRef.current[file.name], durationsRef.current[file.name], {
                            /* TODO: attach thumbnail_url & thumbnail_info */
                        })
                    }
                    //
                    else {
                        content = makeFileContent(content_uri, file.type, file.name, file.size)
                    }

                    client.sendEvent(roomId, 'm.room.message', makeSendableContent(content)).then(onSendResponse).catch(onSendResponse)
                }

                setIsSending(false)
                onDismiss(undefined)
            }
        }
    }
    const files = useMemo(() => {
        const renderableFiles: Array<JSX.Element> = []

        formData?.forEach((value, key) => {
            const file = value as File
            const isVisual = /^(image|video)\//.test(file.type)
            const conditionalProps: Pick<FilesPreviewItemProps, 'file' | 'url'> = {
                url: isVisual ? URL.createObjectURL(file) : undefined,
                file: isVisual ? undefined : file
            }
            const props: FilesPreviewItemProps = {
                ...conditionalProps,
                type: file.type,
                onToggleDiscard: isDiscarded => {
                    if (isDiscarded) {
                        confirmedFiles.current.delete(value as File)
                    }
                    //
                    else {
                        confirmedFiles.current.add(value as File)
                    }

                    if (sendLabelRef.current) {
                        sendLabelRef.current.innerText = getSendLabel()
                    }
                }
            }
            const handleLoadImage = (img: HTMLImageElement | null) => {
                img?.addEventListener('load', () => (dimensionsRef.current[key] = { w: img.width, h: img.height }))
            }
            const handleLoadVideo = (vid: HTMLVideoElement | null) => {
                vid?.addEventListener('loadedmetadata', () => {
                    dimensionsRef.current[key] = { w: vid.videoWidth, h: vid.videoHeight }
                    durationsRef.current[key] = Math.round(vid.duration * 1000)
                })
            }

            confirmedFiles.current.add(value as File)

            // image preview
            if (file.type.startsWith('image/')) {
                renderableFiles.push(<ImagePreview {...props} ref={handleLoadImage} key={key} />)
            }
            // video preview
            else if (file.type.startsWith('video/')) {
                renderableFiles.push(<VideoPreview {...props} ref={handleLoadVideo} key={key} />)
            }
            // PDF preview
            else if (file.type.endsWith('/pdf')) {
                renderableFiles.push(<PDFPreview {...props} key={key} />)
            }
            // audio preview
            else if (file.type.startsWith('audio/')) {
                renderableFiles.push(<AudioPreview {...props} key={key} />)
            }
            // attachment preview
            else {
                renderableFiles.push(<AttachmentPreview {...props} key={key} />)
            }
        })

        return renderableFiles
    }, [formData])
    const getSendLabel = () => `Send ${confirmedFiles.current.size} file${confirmedFiles.current.size > 1 ? 's' : ''}`

    return (
        <div className={`${previewContainerStyle} ${isSending ? 'pointer-events-none' : ''}`}>
            <div className={`${itemContainerStyle} ${isSending ? 'opacity-80' : ''}`}>{files}</div>
            <button {...dismissProps}>
                <Svg d={hiCancel} stroke='currentColor' className='text-neutral-400' />
            </button>
            <button {...sendProps}>{isSending ? <LoadingSpinner size={6} /> : <Svg d={hiPaperPlane} fill='none' stroke='white' />}</button>
            <p className='text-neutral-300' ref={sendLabelRef}>
                {getSendLabel()}
            </p>
        </div>
    )
}

const previewItemStyle = 'rounded-lg border shadow-md transition-opacity cursor-pointer '
const ImagePreview = forwardRef<HTMLImageElement, FilesPreviewItemProps>(({ url, onToggleDiscard }, ref) => {
    const [isDiscarded, setIsDiscarded] = useState(false)

    if (!url) {
        return <Stub />
    }

    const imageProps: Omit<ImageProps, 'alt'> = {
        src: url,
        width: 300,
        height: 300,
        className: `${previewItemStyle} ${isDiscarded ? 'opacity-25' : ''}`,
        onClick: () => {
            const newState = !isDiscarded
            setIsDiscarded(newState)
            onToggleDiscard(newState)
        }
    }

    return (
        <div className='relative'>
            <Image {...imageProps} alt='Preview Image' ref={ref} />
            <PreviewOverlay isVisible={isDiscarded} />
        </div>
    )
})

type PreviewOverlayProps = {
    isVisible: boolean
}

const PreviewOverlay: FC<PreviewOverlayProps> = ({ isVisible }) => {
    const overlayProps: ComponentProps<'button'> = {
        className: `absolute top-0 left-0 w-full h-full transition-opacity pointer-events-none ${isVisible ? 'opacity-100' : 'opacity-0'}`
    }

    return (
        <button {...overlayProps}>
            <Svg d={hiNoSymbol} fill='none' stroke='currentColor' className='w-6 h-full m-auto text-red-600' />
        </button>
    )
}

const VideoPreview = forwardRef<HTMLVideoElement, FilesPreviewItemProps>(({ url, onToggleDiscard }, ref) => {
    const [isDiscarded, setIsDiscarded] = useState(false)

    if (!url) {
        return <Stub />
    }

    const videoProps: ComponentProps<'video'> = {
        src: url,
        controls: !isDiscarded,
        className: `w-full max-w-[200px] max-h-[200px] ${previewItemStyle} ${isDiscarded ? 'opacity-25' : ''}`,
        onClick: evt => {
            const newState = !isDiscarded
            setIsDiscarded(newState)
            onToggleDiscard(newState)
            evt.preventDefault()
        }
    }

    return (
        <div className='relative'>
            <video {...videoProps} ref={ref} />
            <PreviewOverlay isVisible={isDiscarded} />
        </div>
    )
})

const PDFPreview: FC<FilesPreviewItemProps> = ({ file, onToggleDiscard }) => {
    const [isDiscarded, setIsDiscarded] = useState(false)

    if (!file) {
        return <Stub />
    }

    const containerProps: ComponentProps<'div'> = {
        className: `max-w-[200px] max-h-[200px] m-auto p-4 overflow-hidden ${previewItemStyle} ${isDiscarded ? 'opacity-25' : ''}`,
        onClick: () => {
            const newState = !isDiscarded
            setIsDiscarded(newState)
            onToggleDiscard(newState)
        }
    }

    return (
        <div className='relative w-full'>
            <div {...containerProps}>
                <Svg paths={biFilePdf.map(d => ({ d, fill: 'currentColor' }))} box={{ x: 0, y: 0, w: 16, h: 16 }} className='w-auto max-h-[100px] m-auto text-neutral-400' />
                <p className='pt-2 text-center truncate'>{file.name}</p>
            </div>
            <PreviewOverlay isVisible={isDiscarded} />
        </div>
    )
}

const AudioPreview: FC<FilesPreviewItemProps> = ({ file, onToggleDiscard }) => {
    const [isDiscarded, setIsDiscarded] = useState(false)

    if (!file) {
        return <Stub />
    }

    const containerProps: ComponentProps<'div'> = {
        className: `max-w-[200px] max-h-[200px] m-auto p-4 overflow-hidden ${previewItemStyle} ${isDiscarded ? 'opacity-25' : ''}`,
        onClick: () => {
            const newState = !isDiscarded
            setIsDiscarded(newState)
            onToggleDiscard(newState)
        }
    }

    return (
        <div className='relative w-full'>
            <div {...containerProps}>
                <Svg d={hiMusicalNote} fill='none' strokeWidth={1.8} stroke='currentColor' className='w-auto max-h-[100px] m-auto text-neutral-400' />
                <p className='pt-2 text-center truncate'>{file.name}</p>
            </div>
            <PreviewOverlay isVisible={isDiscarded} />
        </div>
    )
}

const AttachmentPreview: FC<FilesPreviewItemProps> = ({ file, onToggleDiscard }) => {
    const [isDiscarded, setIsDiscarded] = useState(false)

    if (!file) {
        return <Stub />
    }

    const containerProps: ComponentProps<'div'> = {
        className: `max-w-[200px] max-h-[200px] m-auto p-4 overflow-hidden ${previewItemStyle} ${isDiscarded ? 'opacity-25' : ''}`,
        onClick: () => {
            const newState = !isDiscarded
            setIsDiscarded(newState)
            onToggleDiscard(newState)
        }
    }

    return (
        <div className='relative w-full'>
            <div {...containerProps}>
                <Svg d={miAttachment} fill='currentColor' className='w-auto max-h-[100px] m-auto text-neutral-400' />
                <p className='pt-2 text-center truncate'>{file.name}</p>
            </div>
            <PreviewOverlay isVisible={isDiscarded} />
        </div>
    )
}
