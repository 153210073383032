import { FC } from 'react'

import { LoadingSpinner } from './LoadingSpinner'

export interface LoadingModalProps {
    visible: boolean
    title: string
}

export const LoadingModal: FC<LoadingModalProps> = ({ visible, title }) => {
    if (!visible) {
        return null
    }
    return (
        <div className='z-50 absolute top-20 left-1/2 transform -translate-x-1/2'>
            <div className='bg-slate-100 rounded-full'>
                <div className='flex flex-row items-center mx-1 py-1 pl-2'>
                    <LoadingSpinner size={4} />
                    <div className='text-[14px] text-[#737D8C] px-2'>{title}</div>
                </div>
            </div>
        </div>
    )
}
