import { FC } from 'react'
import { useRouter } from 'next/router'

import { composeUrlState } from '../../hooks/router/useValidRoute'

import { Svg, SvgProps } from '../Common'

const icon = 'M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7 M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z'

export const StartNewChatButton: FC = () => {
    const router = useRouter()
    const iconProps: SvgProps = {
        d: icon,
        stroke: '#56BA8E',
        fill: 'none',
        className: 'w-7 h-7 p-1 rounded-md hover:bg-[#00000010] cursor-pointer',
        onClick: () => router.push(composeUrlState({ f: 'Contact' }))
    }

    return (
        <>
            <Svg {...iconProps} />
        </>
    )
}
