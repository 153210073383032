import { FC, useState } from 'react'

import { ContactSync, ContactSyncDialog as HeadlessDialog, ContactSyncDialogProps as HeadlessProps } from '@closer/headless-components/components'

import { mdAccountSync, ModalPortal, Svg, SvgProps } from '../Common'

export const SyncContact: FC = () => {
    const [isOpened, setIsOpened] = useState(false)
    const [syncType, setSyncType] = useState<ContactSync>('Whatsapp')
    // TODO: setup web FCM
    const deviceToken = 'web-browser-token'

    const headlessProps: HeadlessProps = {
        deviceToken,
        render: ({ isLoading, isRequested, syncStatus, contactSyncOptions, sendContactSyncRequest }) => {
            const iconProps: SvgProps = {
                d: mdAccountSync,
                stroke: 'none',
                fill: '#56BA8E',
                className: 'w-7 h-7 p-1 rounded-md hover:bg-[#00000010] cursor-pointer',
                onClick: async () => setIsOpened(true)
            }
            return (
                <div>
                    <Svg {...iconProps} />
                    {isOpened && (
                        <ModalPortal selector={'#modal'}>
                            <div className='z-20 top-0 left-0 fixed flex grow justify-center items-center w-full h-full bg-gray-50/10 p-2 backdrop-blur-sm' onClick={() => setIsOpened(false)}>
                                <div className='z-20 p-4 w-80 h-44 bg-white rounded-md shadow-md flex flex-col items-center' onClick={event => event.stopPropagation()}>
                                    <div className='mb-2'>Contact Syncing</div>

                                    <div className='flex flex-col h-16'>
                                        <div>{syncStatus}</div>
                                    </div>

                                    {!isLoading && !isRequested && (
                                        <div className='flex flex-col h-20'>
                                            {contactSyncOptions.map(option => (
                                                <div className='flex flex-row justify-center items-center grow' key={option}>
                                                    <input className='checked:bg-[#56BA8E] hover:bg-[#56BA8E]' type='radio' checked={syncType === option} onChange={() => setSyncType(option)} id={`option-${option}`} value={option} />
                                                    <label className='ml-2' htmlFor={`option-${option}`}>
                                                        {option}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                    <div className='container flex mt-auto justify-end'>
                                        <>
                                            {!isLoading && !isRequested && (
                                                <button className='shadow-md m-2 p-2 rounded-md' onClick={() => sendContactSyncRequest(syncType)}>
                                                    Ok
                                                </button>
                                            )}
                                            <button className='shadow-md m-2 p-2 rounded-md' onClick={() => setIsOpened(false)}>
                                                {isRequested ? 'Close' : 'Cancel'}
                                            </button>
                                        </>
                                    </div>
                                </div>
                            </div>
                        </ModalPortal>
                    )}
                </div>
            )
        }
    }

    return <HeadlessDialog {...headlessProps} />
}
